@screen-sm: 40rem;
@screen-md: 48rem;
@screen-lg: 60rem;
@screen-xl: 75rem;
@screen-xxl: 100rem;

:root {
  --screen-sm: @screen-sm;
  --screen-md: @screen-md;
  --screen-lg: @screen-lg;
  --screen-xl: @screen-xl;
  --screen-xxl: @screen-xxl;

  --font-nunito: "Nunito", Arial, Helvetica, sans-serif;
  --font-open-sans: "Open Sans", Arial, Helvetica, sans-serif;
  --font-hey-gorgeous: "Hey Gorgeous", Arial, Helvetica, sans-serif;

  --font-size-1: 0.75rem;
  --font-size-2: 0.875rem;
  --font-size-3: 1rem;
  --font-size-4: 1.125rem;
  --font-size-5: 1.25rem;
  --font-size-6: 1.875rem;
  --font-size-7: 2.25rem;
  --font-size-8: 3rem;
  --font-size-9: 3.75rem;
  --font-size-10: 4.5rem;

  // #region colors
  --color-main-darkest: hsl(140, 50%, 10%);
  --color-main-darker: hsl(140, 50%, 20%);
  --color-main-dark: hsl(140, 50%, 35%);
  --color-main: hsl(140, 70%, 45%);
  --color-main-light: hsl(140, 70%, 60%);
  --color-main-lighter: hsl(140, 70%, 70%);
  --color-main-lightest: hsl(140, 50%, 90%);

  --color-accent: hsl(40, 86%, 49%);
  --color-black: hsl(137, 14%, 10%);
  --color-gray: hsl(140, 15%, 23%);
  --color-gray-lighter: hsl(0, 0%, 95%);
  --color-white: hsl(0, 0%, 100%);
  // #endregion

  --space-size-1: 0.25rem;
  --space-size-2: 0.5rem;
  --space-size-3: 0.75rem;
  --space-size-4: 1rem;
  --space-size-5: 1.5rem;
  --space-size-6: 2rem;
  --space-size-7: 3rem;
  --space-size-8: 4rem;
  --space-size-9: 6rem;
  --space-size-10: 8rem;
  --space-size-11: 12rem;
  --space-size-12: 16rem;
  --space-size-13: 24rem;
  --space-size-14: 32rem;
  --space-size-15: 40rem;
  --space-size-16: 48rem;
}

.body-light-mode() {
  --background-color: var(--color-white);
  --text-color: var(--color-black);
  --link-color: var(--color-main-dark);
  --link-color--active: var(--color-main-darker);
}

.body-dark-mode() {
  --background-color: var(--color-black);
  --text-color: var(--color-white);
  --link-color: var(--color-main-light);
  --link-color--active: var(--color-main-lighter);
}

body {
  @media (prefers-color-scheme: light) {
    .body-light-mode();
  }

  @media (prefers-color-scheme: dark) {
    .body-dark-mode();
  }

  &.light {
    .body-light-mode();
  }

  &.dark {
    .body-dark-mode();
  }
}
