.skip-link {
  background-color: var(--background-color);
  left: var(--space-size-3);
  padding: var(--space-size-3) var(--space-size-2);
  position: absolute;
  top: var(--space-size-3);
}

.header {
  flex-direction: row;
  font-family: var(--font-nunito);
  font-size: var(--font-size-6);
  justify-content: space-between;
  padding: var(--space-size-3) 0;
  gap: var(--space-size-3);
  @media screen and (max-width: 60rem) {
    flex-direction: column;
    justify-content: center;
  }
}

.header__name {
  margin: 0 var(--space-size-4);
  padding: 0.25ch 0.75ch;
  text-decoration: none;
}

.header__nav {
  &-list {
    margin: 0;

    li {
      margin-right: var(--space-size-4);
    }
  }

  &-link {
    border-bottom: 0.125rem solid transparent;
    padding: 0.25ch 0.75ch;
    text-decoration: none;
    position: relative;
    isolation: isolate;
    outline: none;

    &::before,
    &::after {
      position: absolute;
      inset: 0;
      content: "";
      z-index: -1;
      margin: -5%;
      box-shadow: inset 0 0 0 2px transparent;
      @media (prefers-reduced-motion: no-preference) {
        animation: link-border-anim var(--anim-time, 6s) linear infinite;
        transition: box-shadow 150ms ease-out;
      }
    }

    &:hover,
    &:focus {
      &::before,
      &::after {
        box-shadow: inset 0 0 0 2px;
      }

      &::before {
        @media (prefers-reduced-motion: no-preference) {
          animation-delay: var(--anim-offset, -3s);
          transition-timing-function: ease-in;
        }
      }
    }

    &[aria-current],
    &[data-state="active"] {
      border-color: currentColor;
    }
  }
}

@keyframes link-border-anim {
  0%,
  100% {
    clip-path: inset(0 0 calc(100% - 2px) 0);
  }
  25% {
    clip-path: inset(0 calc(100% - 2px) 0 0);
  }
  50% {
    clip-path: inset(calc(100% - 2px) 0 0 0);
  }
  75% {
    clip-path: inset(0 0 0 calc(100% - 2px));
  }
}
