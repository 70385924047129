.position {
  &-absolute {
    position: absolute !important;
  }
  &-fixed {
    position: fixed !important;
  }
  &-relative {
    position: relative !important;
  }
  &-static {
    position: static !important;
  }
  &-sticky {
    position: sticky !important;
  }
}
