:root {
  --screen-sm: 40rem;
  --screen-md: 48rem;
  --screen-lg: 60rem;
  --screen-xl: 75rem;
  --screen-xxl: 100rem;
  --font-nunito: "Nunito", Arial, Helvetica, sans-serif;
  --font-open-sans: "Open Sans", Arial, Helvetica, sans-serif;
  --font-hey-gorgeous: "Hey Gorgeous", Arial, Helvetica, sans-serif;
  --font-size-1: 0.75rem;
  --font-size-2: 0.875rem;
  --font-size-3: 1rem;
  --font-size-4: 1.125rem;
  --font-size-5: 1.25rem;
  --font-size-6: 1.875rem;
  --font-size-7: 2.25rem;
  --font-size-8: 3rem;
  --font-size-9: 3.75rem;
  --font-size-10: 4.5rem;
  --color-main-darkest: hsl(140, 50%, 10%);
  --color-main-darker: hsl(140, 50%, 20%);
  --color-main-dark: hsl(140, 50%, 35%);
  --color-main: hsl(140, 70%, 45%);
  --color-main-light: hsl(140, 70%, 60%);
  --color-main-lighter: hsl(140, 70%, 70%);
  --color-main-lightest: hsl(140, 50%, 90%);
  --color-accent: hsl(40, 86%, 49%);
  --color-black: hsl(137, 14%, 10%);
  --color-gray: hsl(140, 15%, 23%);
  --color-gray-lighter: hsl(0, 0%, 95%);
  --color-white: hsl(0, 0%, 100%);
  --space-size-1: 0.25rem;
  --space-size-2: 0.5rem;
  --space-size-3: 0.75rem;
  --space-size-4: 1rem;
  --space-size-5: 1.5rem;
  --space-size-6: 2rem;
  --space-size-7: 3rem;
  --space-size-8: 4rem;
  --space-size-9: 6rem;
  --space-size-10: 8rem;
  --space-size-11: 12rem;
  --space-size-12: 16rem;
  --space-size-13: 24rem;
  --space-size-14: 32rem;
  --space-size-15: 40rem;
  --space-size-16: 48rem;
}
@media (prefers-color-scheme: light) {
  body {
    --background-color: var(--color-white);
    --text-color: var(--color-black);
    --link-color: var(--color-main-dark);
    --link-color--active: var(--color-main-darker);
  }
}
@media (prefers-color-scheme: dark) {
  body {
    --background-color: var(--color-black);
    --text-color: var(--color-white);
    --link-color: var(--color-main-light);
    --link-color--active: var(--color-main-lighter);
  }
}
body.light {
  --background-color: var(--color-white);
  --text-color: var(--color-black);
  --link-color: var(--color-main-dark);
  --link-color--active: var(--color-main-darker);
}
body.dark {
  --background-color: var(--color-black);
  --text-color: var(--color-white);
  --link-color: var(--color-main-light);
  --link-color--active: var(--color-main-lighter);
}
*:focus-visible {
  outline: 0.15rem dashed currentColor;
}
*:focus:not(:focus-visible) {
  outline-width: 0;
}
*::selection {
  background-color: var(--text-color);
  color: var(--background-color);
}
@font-face {
  font-display: swap;
  font-family: "Hey Gorgeous";
  src: url("../fonts/Hey-Gorgeous.woff2") format("woff2"), url("../fonts/Hey-Gorgeous.woff") format("woff"), url("../fonts/Hey-Gorgeous.ttf") format("ttf");
}
:root {
  font-size: 1rem;
}
body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--font-nunito);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-nunito);
  font-weight: 400;
  margin: var(--space-size-4) 0 var(--space-size-2);
}
h1,
.h1 {
  font-size: var(--font-size-8);
  letter-spacing: calc(-0.02 * var(--font-size-8));
}
@media screen and (max-width: 40rem) {
  h1,
  .h1 {
    font-size: var(--font-size-7);
    letter-spacing: calc(-0.02 * var(--font-size-7));
  }
}
h2,
.h2 {
  font-size: var(--font-size-6);
  font-weight: 700;
  letter-spacing: calc(-0.02 * var(--font-size-6));
}
h3,
.h3 {
  font-size: var(--font-size-5);
  letter-spacing: calc(-0.02 * var(--font-size-5));
}
h4,
.h4 {
  font-size: var(--font-size-4);
  letter-spacing: calc(-0.02 * var(--font-size-4));
}
h5,
.h5 {
  font-size: var(--font-size-3);
  letter-spacing: calc(-0.02 * var(--font-size-3));
}
h6,
.h6 {
  font-size: var(--font-size-2);
  letter-spacing: calc(-0.02 * var(--font-size-2));
}
a {
  color: var(--link-color);
}
a:active {
  color: var(--link-color--active);
}
code:not([class]) {
  color: var(--color-accent);
}
main {
  font-size: var(--font-size-5);
}
p {
  margin: 0 0 1.5em;
}
button,
.btn {
  -webkit-appearance: none;
  background-color: var(--btn-background-color, transparent);
  border: none;
  border-radius: 0.317em;
  color: var(--btn-color, inherit);
  cursor: pointer;
  display: inline-block;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: var(--btn-padding, 0.75em 1em);
  width: auto;
}
button.primary,
.btn.primary {
  --btn-background-color: var(--link-color);
  --btn-text-color: var(--background-color);
}
.site-main {
  margin: var(--space-size-7) auto;
  max-width: 60em;
  outline: none;
  padding: 0 var(--space-size-5);
}
.skip-link {
  background-color: var(--background-color);
  left: var(--space-size-3);
  padding: var(--space-size-3) var(--space-size-2);
  position: absolute;
  top: var(--space-size-3);
}
.header {
  flex-direction: row;
  font-family: var(--font-nunito);
  font-size: var(--font-size-6);
  justify-content: space-between;
  padding: var(--space-size-3) 0;
  gap: var(--space-size-3);
}
@media screen and (max-width: 60rem) {
  .header {
    flex-direction: column;
    justify-content: center;
  }
}
.header__name {
  margin: 0 var(--space-size-4);
  padding: 0.25ch 0.75ch;
  text-decoration: none;
}
.header__nav-list {
  margin: 0;
}
.header__nav-list li {
  margin-right: var(--space-size-4);
}
.header__nav-link {
  border-bottom: 0.125rem solid transparent;
  padding: 0.25ch 0.75ch;
  text-decoration: none;
  position: relative;
  isolation: isolate;
  outline: none;
}
.header__nav-link::before,
.header__nav-link::after {
  position: absolute;
  inset: 0;
  content: "";
  z-index: -1;
  margin: -5%;
  box-shadow: inset 0 0 0 2px transparent;
}
@media (prefers-reduced-motion: no-preference) {
  .header__nav-link::before,
  .header__nav-link::after {
    animation: link-border-anim var(--anim-time, 6s) linear infinite;
    transition: box-shadow 150ms ease-out;
  }
}
.header__nav-link:hover::before,
.header__nav-link:focus::before,
.header__nav-link:hover::after,
.header__nav-link:focus::after {
  box-shadow: inset 0 0 0 2px;
}
@media (prefers-reduced-motion: no-preference) {
  .header__nav-link:hover::before,
  .header__nav-link:focus::before {
    animation-delay: var(--anim-offset, -3s);
    transition-timing-function: ease-in;
  }
}
.header__nav-link[aria-current],
.header__nav-link[data-state="active"] {
  border-color: currentColor;
}
@keyframes link-border-anim {
  0%,
  100% {
    clip-path: inset(0 0 calc(100% - 2px) 0);
  }
  25% {
    clip-path: inset(0 calc(100% - 2px) 0 0);
  }
  50% {
    clip-path: inset(calc(100% - 2px) 0 0 0);
  }
  75% {
    clip-path: inset(0 0 0 calc(100% - 2px));
  }
}
.d-flex {
  display: flex !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-none {
  display: none !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-no-wrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-baseline {
  justify-content: baseline !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-evenly {
  justify-content: space-evenly !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-relative {
  position: relative !important;
}
.position-static {
  position: static !important;
}
.position-sticky {
  position: sticky !important;
}
.text-align-left {
  text-align: left !important;
}
.text-align-center {
  text-align: center !important;
}
.text-align-right {
  text-align: right !important;
}
.text-align-justify {
  text-align: justify !important;
}
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
.visually-hidden-focusable:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
