@font-face {
  font-display: swap;
  font-family: "Hey Gorgeous";
  src: url("../../fonts/Hey-Gorgeous.woff2") format("woff2"),
    url("../../fonts/Hey-Gorgeous.woff") format("woff"),
    url("../../fonts/Hey-Gorgeous.ttf") format("ttf");
}

:root {
  font-size: 1rem;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--font-nunito);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-nunito);
  font-weight: 400;
  margin: var(--space-size-4) 0 var(--space-size-2);
}

h1,
.h1 {
  font-size: var(--font-size-8);
  letter-spacing: calc(-0.02 * var(--font-size-8));

  @media screen and (max-width: @screen-sm) {
    font-size: var(--font-size-7);
    letter-spacing: calc(-0.02 * var(--font-size-7));
  }
}

h2,
.h2 {
  font-size: var(--font-size-6);
  font-weight: 700;
  letter-spacing: calc(-0.02 * var(--font-size-6));
}

h3,
.h3 {
  font-size: var(--font-size-5);
  letter-spacing: calc(-0.02 * var(--font-size-5));
}

h4,
.h4 {
  font-size: var(--font-size-4);
  letter-spacing: calc(-0.02 * var(--font-size-4));
}

h5,
.h5 {
  font-size: var(--font-size-3);
  letter-spacing: calc(-0.02 * var(--font-size-3));
}

h6,
.h6 {
  font-size: var(--font-size-2);
  letter-spacing: calc(-0.02 * var(--font-size-2));
}

a {
  color: var(--link-color);

  &:active {
    color: var(--link-color--active);
  }
}

code:not([class]) {
  color: var(--color-accent);
}

main {
  font-size: var(--font-size-5);
}

p {
  margin: 0 0 1.5em;
}
