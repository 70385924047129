.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-no-wrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content {
  &-baseline {
    justify-content: baseline !important;
  }
  &-around {
    justify-content: space-around !important;
  }
  &-between {
    justify-content: space-between !important;
  }
  &-evenly {
    justify-content: space-evenly !important;
  }
  &-start {
    justify-content: flex-start !important;
  }
  &-center {
    justify-content: center !important;
  }
  &-end {
    justify-content: flex-end !important;
  }
}

.align-items {
  &-baseline {
    align-items: baseline !important;
  }
  &-stretch {
    align-items: stretch !important;
  }
  &-start {
    align-items: flex-start !important;
  }
  &-center {
    align-items: center !important;
  }
  &-end {
    align-items: flex-end !important;
  }
}

.align-self {
  &-baseline {
    align-self: baseline !important;
  }
  &-stretch {
    align-self: stretch !important;
  }
  &-start {
    align-self: flex-start !important;
  }
  &-center {
    align-self: center !important;
  }
  &-end {
    align-self: flex-end !important;
  }
}
