.d {
  &-flex {
    display: flex !important;
  }
  &-block {
    display: block !important;
  }
  &-grid {
    display: grid !important;
  }
  &-inline {
    display: inline !important;
  }
  &-inline-block {
    display: inline-block !important;
  }
  &-none {
    display: none !important;
  }
}
